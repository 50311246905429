import { render, staticRenderFns } from "./JiraSubtasks.vue?vue&type=template&id=1b7a6fa0&scoped=true&"
import script from "./JiraSubtasks.vue?vue&type=script&lang=js&"
export * from "./JiraSubtasks.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b7a6fa0",
  null
  
)

export default component.exports