<template>
  <Portlet
    :title="title"
    icon="tasks"
    class="jiraSubtasks"
  >
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <!-- bp -->
      <table class="defaultTable">
        <colgroup>
          <col>
          <col>
          <col>
        </colgroup>
        <thead>
          <tr>
            <th class="font-weight-bold">
              Issue
            </th>
            <th class="font-weight-bold">
              Status
            </th>
            <th class="font-weight-bold">
              Assignee
            </th>
            <th class="font-weight-bold d-none d-md-table-cell">
              Created
            </th>
            <th class="font-weight-bold d-none d-md-table-cell">
              Updated
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in jiraSubtasks"
            :key="'tr' + index"
          >
            <td>
              <a
                :href="jiraBaseUrl.baseUrl + item.key"
                target="_blank"
              >{{ item.key }}</a>
              <span>: {{ item.summary }}</span>
            </td>
            <td>
              <span>{{ item.status }}</span>
            </td>
            <td>
              <span>{{ item.assignee }}</span>
            </td>
            <td class="d-none d-md-table-cell">
              <span v-tooltip="dateTime_dateTime(item.created)">{{ dateTime_fromNow(item.created) }}</span>
            </td>
            <td class="d-none d-md-table-cell">
              <span v-tooltip="dateTime_dateTime(item.updated)">{{ dateTime_fromNow(item.updated) }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </Portlet>
</template>

<script>
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';

export default {
  name: 'TriggerListOperation',
  mixins: [
    dateTimeMixin,
  ],
  props: {
    installationId: {
      type: String,
      required: true
    },
    jiraSubtasks: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: false,
      default () {
        return 'Jira Subtasks'
      }
    }
  },
  data () {
    return {
      jiraBaseUrl: null,
      loading: true,
    }
  },
  created () {
    this.getJiraBaseUrl();
  },
  methods: {
//#region API-calls
    getJiraBaseUrl () {
      this.loading = true;
      this.axios.get(`/Issue/GetJiraBaseUrl`)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.jiraBaseUrl = response.data;
        })
        .catch(() => {
          if (this.axios.isCancel()) {
            return;
          }
        })        
        .finally(() => {
          this.loading = false;
        });
    },
//#endregion
  }
}
</script>

<style scoped>

</style>

